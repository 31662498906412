$(document).ready(function() {
    equalBoxHeight();

    function equalBoxHeight() {
        var height = 0;
        var selector = '.box-height-equal .teaser-boxes-item > h3';
        $(selector).each(function(index, el) {
            $(el).css('height', '');
        });

        $(selector).each(function(index, el) {
            if ($(el).outerHeight() > height) {
                height = $(el).outerHeight();
            }
        });
        $(selector).each(function(index, el) {
            $(el).css('height', height);
        });
    }

    $(window).resize(function(event) {
        equalBoxHeight();
    });
});
